import React from 'react';

const ContentAnalytics = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.5393 48.0016C43.1642 48.0058 42.7925 47.9342 42.4457 47.7914C42.0988 47.6486 41.7842 47.4374 41.5209 47.1703L27.717 33.3668C27.5086 33.1519 27.392 32.8649 27.3909 32.5656C27.3905 32.2571 27.5068 31.96 27.717 31.7343L31.8139 27.6378C32.0308 27.4221 32.324 27.3008 32.6299 27.3008C32.9359 27.3008 33.2298 27.4221 33.4467 27.6378L47.2499 41.471C47.7505 42.0183 48.0283 42.7329 48.0283 43.4746C48.0283 44.2162 47.7505 44.9312 47.2499 45.4784L45.5279 47.1703C45.2702 47.4365 44.9611 47.6478 44.6191 47.7907C44.2772 47.9336 43.9098 48.0052 43.5393 48.0016ZM28.994 32.5656L42.5892 46.1314C42.7134 46.2571 42.8617 46.3568 43.0247 46.4249C43.1878 46.493 43.3626 46.5282 43.5393 46.5282C43.716 46.5282 43.8908 46.493 44.0538 46.4249C44.2169 46.3568 44.3652 46.2571 44.4894 46.1314L46.2106 44.4098C46.3363 44.2856 46.4361 44.1377 46.5041 43.9746C46.5722 43.8116 46.6078 43.6364 46.6078 43.4597C46.6078 43.283 46.5722 43.1082 46.5041 42.9451C46.4361 42.7821 46.3363 42.6342 46.2106 42.5099L32.6452 28.9437L28.994 32.5656Z"
      fill="#FCFCFD"
    />
    <path
      d="M28.6982 32.4417C28.4966 32.4326 28.3059 32.348 28.1641 32.2043L25.1956 29.2358C25.0884 29.0929 25.0358 28.9161 25.0485 28.738C25.0611 28.5598 25.1381 28.3921 25.2645 28.2658C25.3908 28.1395 25.5581 28.0629 25.7363 28.0502C25.9144 28.0375 26.0912 28.0894 26.2341 28.1966L28.5794 30.5121L30.4796 28.6122L28.1641 26.2967C28.0923 26.2275 28.035 26.1446 27.996 26.0528C27.9569 25.961 27.9365 25.8623 27.9365 25.7626C27.9365 25.6628 27.9569 25.5641 27.996 25.4723C28.035 25.3805 28.0923 25.2973 28.1641 25.2281C28.3062 25.0988 28.4916 25.0273 28.6837 25.0273C28.8759 25.0273 29.0605 25.0988 29.2026 25.2281L32.1711 28.1966C32.244 28.2592 32.3034 28.3368 32.3443 28.4238C32.3853 28.5107 32.407 28.6053 32.4088 28.7014C32.4075 28.8019 32.3859 28.9012 32.3451 28.9931C32.3042 29.0849 32.2448 29.1675 32.1711 29.2358L29.2026 32.2043C29.0683 32.3414 28.8894 32.4255 28.6982 32.4417Z"
      fill="#FCFCFD"
    />
    <path
      d="M16.4677 32.7745C13.7802 32.7784 11.1332 32.1188 8.7617 30.8543C6.3902 29.5898 4.36759 27.7596 2.87328 25.5257C1.37896 23.2919 0.459695 20.7237 0.196129 18.0491C-0.0674361 15.3744 0.333396 12.6759 1.36294 10.1934C2.39249 7.7108 4.01854 5.52102 6.0976 3.81791C8.17666 2.11481 10.6445 0.951214 13.2811 0.430529C15.9178 -0.0901556 18.6421 0.0478207 21.2125 0.832754C23.7829 1.61769 26.1196 3.02518 28.0156 4.93001C30.2898 7.2149 31.8365 10.122 32.4611 13.2847C33.0856 16.4474 32.7604 19.7243 31.5254 22.7022C30.2905 25.6801 28.2013 28.2258 25.5218 30.0183C22.8422 31.8108 19.6916 32.7697 16.4677 32.7745ZM16.4677 1.63503C14.0258 1.63451 11.6219 2.23663 9.46831 3.38779C7.3147 4.53894 5.4782 6.20367 4.12199 8.23441C2.76577 10.2652 1.93167 12.5992 1.69342 15.0295C1.45517 17.4598 1.81985 19.9111 2.75587 22.1666C3.6919 24.4221 5.17025 26.4119 7.05931 27.9594C8.94837 29.5068 11.1899 30.5643 13.5855 31.038C15.9811 31.5117 18.4563 31.3869 20.7922 30.6749C23.1281 29.9629 25.2523 28.6858 26.9763 26.9563C29.7588 24.173 31.3225 20.3983 31.3225 16.4626C31.3225 12.5269 29.7588 8.75228 26.9763 5.96891C25.5978 4.58611 23.9601 3.48904 22.1569 2.74024C20.3536 1.99144 18.4203 1.60573 16.4677 1.60531V1.63503Z"
      fill="#FCFCFD"
    />
    <path d="M7 24L7 10" stroke="#49D4ED" strokeWidth="1.21958" />
    <path d="M12 24L12 14" stroke="#49D4ED" strokeWidth="1.21958" />
    <path d="M17 24L17 11" stroke="#49D4ED" strokeWidth="1.21958" />
    <path d="M22 24L22 17" stroke="#49D4ED" strokeWidth="1.21958" />
    <path d="M27 24L27 12" stroke="#49D4ED" strokeWidth="1.21958" />
  </svg>
);

export default ContentAnalytics;
